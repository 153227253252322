import classnames from 'classnames/bind';
import style from './ViewFooterSNS.module.scss';
import React, { FC, useRef } from 'react';
import SNSKakao from 'ui/common/assets/sns/SNS_kakaotalk.svg';
import SNSTwitter from 'ui/common/assets/sns/SNS_twitter.svg';
import SNSInstagram from 'ui/common/assets/sns/SNS_instagram.svg';
import SNSNaver from 'ui/common/assets/sns/SNS_naver.svg';
import SNSHomepage from 'ui/common/assets/sns/SNS_home.svg';
import SNSYoutube from 'ui/common/assets/sns/SNS_youtube.svg';
import SNSFacebook from 'ui/common/assets/sns/SNS_facebook.svg';
import SNSLinkedIn from 'ui/common/assets/sns/SNS_linkdin.svg';
import SNSTikTok from 'ui/common/assets/sns/SNS_tiktok.svg';
import SNSBlog from 'ui/common/assets/sns/SNS_blog.svg';
import SNSTstory from 'ui/common/assets/sns/SNS_tstory.svg';
import { FooterSNSType, IFooterSNS } from 'ui/interface/footer/IFooterSNS';
import { ViewModeType } from '../../interface/common/ViewMode';
import useUrlRouter from '../../hook/useUrlRouter';
import { COLORS } from '../../constants/colors';

const cx = classnames.bind(style);

interface IViewFooterSNSProps {
  viewMode?: ViewModeType;
  colorType: 'WHITE' | 'BLACK';
  footerSNSList: IFooterSNS[];
  isPreview?: boolean;
}

const ViewFooterSNS: FC<IViewFooterSNSProps> = ({ viewMode, colorType, footerSNSList, isPreview }) => {
  const footerSNSRef = useRef<HTMLDivElement>(null);
  const { handleUrlClick } = useUrlRouter();

  const SNS_ICON = (snsType: FooterSNSType) => {
    const fillColor = COLORS.gray600;

    switch (snsType) {
      case 'TWITTER':
        return <SNSTwitter fill={fillColor} />;
      case 'INSTAGRAM':
        return <SNSInstagram fill={fillColor} />;
      case 'NAVER':
        return <SNSNaver fill={fillColor} />;
      case 'HOMEPAGE':
        return <SNSHomepage fill={fillColor} />;
      case 'KAKAO':
        return <SNSKakao fill={fillColor} />;
      case 'YOUTUBE':
        return <SNSYoutube fill={fillColor} />;
      case 'FACEBOOK':
        return <SNSFacebook fill={fillColor} />;
      case 'LINKEDIN':
        return <SNSLinkedIn fill={fillColor} />;
      case 'TIKTOK':
        return <SNSTikTok fill={fillColor} />;
      case 'BLOG':
        return <SNSBlog fill={fillColor} />;
      case 'T_STORY':
        return <SNSTstory fill={fillColor} />;
    }
  };

  const handleClick = (url: string) => {
    handleUrlClick({ url, isExternal: true });
  };

  const listEl =
    footerSNSList.filter(({ useYn }) => useYn).length > 0 &&
    footerSNSList
      .filter(({ useYn }) => useYn)
      .map(({ snsType, url }) => {
        return (
          <div
            key={snsType}
            className={cx('icon', snsType, { darkMode: colorType === 'BLACK' })}
            onClick={() => handleClick(url)}
          >
            {SNS_ICON(snsType)}
          </div>
        );
      });

  return (
    <div className={cx('container', viewMode)}>
      <div className={cx('content')} ref={footerSNSRef}>
        {listEl}
      </div>
    </div>
  );
};

export default ViewFooterSNS;
