import classnames from 'classnames/bind';
import style from './ViewFooterText.module.scss';
import React, { FC, useEffect, useRef } from 'react';
import { ViewModeType } from '../../interface/common/ViewMode';

const cx = classnames.bind(style);

interface IViewFooterTextProps {
  viewMode?: ViewModeType;
  colorType: 'WHITE' | 'BLACK';
  footerText: string;
}

const ViewFooterText: FC<IViewFooterTextProps> = ({ viewMode, footerText, colorType }) => {
  const footerTextRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    handleReSizeHeight();
  }, [viewMode]);

  const handleReSizeHeight = () => {
    if (!footerTextRef.current) return;
    footerTextRef.current.style.height = 'auto';
    footerTextRef.current.style.height = `${footerTextRef.current.scrollHeight}px`;
  };

  return (
    <div className={cx('container')}>
      <textarea
        className={cx('textarea', { darkMode: colorType === 'BLACK' })}
        value={footerText}
        onChange={(e) => {}}
        ref={footerTextRef}
        rows={1}
        wrap={'on'}
        maxLength={200}
        disabled
      />
    </div>
  );
};

export default ViewFooterText;
