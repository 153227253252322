// Created by kdw0601 on 2022-12-27
import { forwardRef, InputHTMLAttributes, useId } from 'react';
import Icon from '../icon/Icon';
import styles from './Checkbox.module.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

interface IProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  indeterminate?: boolean;
  onChange?: (val: boolean) => void;
  variant?: 'box' | 'circle';
}

const Checkbox = forwardRef<HTMLInputElement, IProps>(function NewCheckbox(props, ref) {
  const { className, checked, variant = 'box', onChange, indeterminate, id, ...htmlAttr } = props;
  const innerId = useId();

  const handleChange = () => {
    if (htmlAttr.disabled || !onChange) return;
    if (indeterminate) {
      onChange(true);
      return;
    }
    onChange(!props.checked);
  };

  return (
    <>
      <input
        type={'checkbox'}
        className={cx('input')}
        onChange={handleChange}
        id={id ?? innerId}
        checked={checked}
        ref={ref}
        {...htmlAttr}
      />
      <label htmlFor={id ?? innerId} className={cx('checkbox', variant, className, indeterminate && 'indeterminate')}>
        {(checked || indeterminate) && <Icon name={indeterminate ? 'remove_line' : 'check_line'} size={18} />}
      </label>
    </>
  );
});

export default Checkbox;
