// Created by ksy0105 on 2022-03-10

import { useContext, useEffect } from 'react';
import GlobalContext from '../GlobalContext';

interface IProps {
  top?: number;
  isFullMode?: boolean;
  ignoreCountModal?: boolean;
}

const useBlockBodyScrollingInModal = ({
  top = window.scrollY,
  ignoreCountModal = false,
  isFullMode = true,
}: IProps) => {
  const {
    modal: { modalCount, increaseModalCount, decreaseModalCount },
  } = useContext(GlobalContext);

  useEffect(() => {
    if (ignoreCountModal) return;
    increaseModalCount();
    const { cssText } = document.body.style;
    document.body.style.cssText = `top: -${top}px; width: 100vw; min-width:100vw; min-height: 100%; overflow-x:hidden;${
      isFullMode ? 'overflow:hidden;' : ''
    }`;

    return () => {
      if (ignoreCountModal) return;
      // 처음 열린 모달이 닫힐 때만 block 해제
      if (modalCount <= 1) {
        const scrollY = document.body.style.top;

        document.body.style.cssText = cssText;
        window.scrollTo(0, parseInt(scrollY || '0') * -1);
      }

      decreaseModalCount();
    };
  }, []);
};

export default useBlockBodyScrollingInModal;
