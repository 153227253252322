import IViewHeader from '../interface/header/IViewHeader';
import { IHeaderDto, ITemplateMenuDto } from '../interface/ITemplatePageRs';
import { ICompanyLogo } from '../interface/header/ICompanyLogo';
import { IHeaderUnionStyle } from '../interface/header/IHeaderStyle';

const useViewHeaderWithResponse = () => {
  const getViewHeaderWithResponse: (
    header: IHeaderDto<IHeaderUnionStyle>,
    menu: ITemplateMenuDto,
    logo: ICompanyLogo
  ) => IViewHeader<IHeaderUnionStyle> = (header, menu, logo) => {
    const { style } = header;
    const { mainMenu, menuList } = menu;

    return {
      companyLogo: logo,
      headerStyle: style,
      main: mainMenu,
      lnbList: menuList,
    };
  };

  return {
    getViewHeaderWithResponse,
  };
};

export default useViewHeaderWithResponse;
