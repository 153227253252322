import classnames from 'classnames/bind';
import { useEffect, useState } from 'react';
import Icon from '../../../common/icon/Icon';
import useUrlRouter from '../../../hook/useUrlRouter';
import { VIEW_MODE, ViewModeType } from '../../../interface/common/ViewMode';
import { IHeaderJobflexStyle, IHeaderStyle, IHeaderUnionStyle } from '../../../interface/header/IHeaderStyle';
import styles from './HeaderMyButton.module.scss';
import ModalPreviewMyPage from './ModalPreviewMyPage';
import { checkIsDarkMode } from '../../../util/editor/color.utils';
import useCheckMouseOver from '../../../hook/effect/useCheckMouseOver';
import { MyMenuType } from '../../../interface/header/IMenu';
import ModalPreviewResumeUpdate from './ModalPreviewResumeUpdate';
import { HasOption } from '../../../interface/common/hasOption';
import { LANGUAGE, Language } from '../../../constants/language';
const cx = classnames.bind(styles);

interface IProps {
  viewMode?: ViewModeType;
  headerStyle: IHeaderStyle<IHeaderUnionStyle>;
  qnaOpt: {
    url: string;
    type: 'EXTERNAL' | 'INTERNAL';
  };
  resumeUpdateOpt: {
    url: string;
    type: 'EXTERNAL' | 'INTERNAL';
  };
  isPreview?: boolean;
  isEdit?: boolean;
}

export const MY_BUTTON_TEXT: Record<MyMenuType | 'TOTAL', { KOR: string; ENG: string }> = {
  TOTAL: { KOR: 'MY', ENG: 'MY' },
  MY_PAGE: {
    KOR: '마이페이지',
    ENG: 'MY PAGE',
  },
  QNA: {
    KOR: '1:1 문의하기',
    ENG: 'Contact Us',
  },
  RESUME_UPDATE: {
    KOR: '지원서 수정',
    ENG: 'Update Resume',
  },
};

interface IMyMenu {
  title: string;
  url: string;
  urlType: 'EXTERNAL' | 'INTERNAL';
}

const HeaderMyButton = ({
  viewMode,
  headerStyle,
  qnaOpt: { url: QnAURL, type: QnAUrlType },
  resumeUpdateOpt: { url: resumeUpdatePageUrl, type: resumeUpdatePageUrlType },
  isPreview,
  isEdit = false,
  options,
}: IProps & HasOption) => {
  const { handleUrlClick } = useUrlRouter();
  const { color, backgroundColor } = headerStyle;
  const [showMyPageModal, setShowMyPageModal] = useState(false);
  const [showResumeUpdateModal, setShowResumeUpdateModal] = useState(false);
  const { myPageStatusType, recruiterDomain, myPageUrl, qnaStatusType, resumeUpdatePageStatusType } =
    headerStyle as IHeaderStyle<IHeaderJobflexStyle>;
  const { ref: dropdownRef, mouseOver: isDropdownMouseOver } = useCheckMouseOver<HTMLUListElement>();
  const [myMenu, setMyMenu] = useState<IMyMenu[]>([]);

  const languageType = (options && (options['languageType'] as Language)) || LANGUAGE.KOR;
  const useLanguageSetting = (options && (options['useLanguageSetting'] as boolean)) || false;

  const INITIAL_MY_MENU: IMyMenu[] = [
    { title: MY_BUTTON_TEXT.RESUME_UPDATE[languageType], url: resumeUpdatePageUrl, urlType: resumeUpdatePageUrlType },
    {
      title: MY_BUTTON_TEXT.MY_PAGE[languageType],
      url: myPageUrl ? myPageUrl : `${recruiterDomain}/app/applicant/myPage/login`,
      urlType: 'EXTERNAL',
    },
    { title: MY_BUTTON_TEXT.QNA[languageType], url: QnAURL, urlType: QnAUrlType },
  ];

  useEffect(() => {
    let newMyMenu = [...INITIAL_MY_MENU];
    if (!myPageStatusType || myPageStatusType === 'OFF') {
      newMyMenu = newMyMenu.filter((menu) => menu.title !== MY_BUTTON_TEXT.MY_PAGE[languageType]);
    }
    if (!qnaStatusType || qnaStatusType === 'OFF') {
      newMyMenu = newMyMenu.filter((menu) => menu.title !== MY_BUTTON_TEXT.QNA[languageType]);
    }
    if (!resumeUpdatePageStatusType || resumeUpdatePageStatusType === 'OFF') {
      newMyMenu = newMyMenu.filter((menu) => menu.title !== MY_BUTTON_TEXT.RESUME_UPDATE[languageType]);
    }
    setMyMenu(newMyMenu);
  }, [myPageStatusType, qnaStatusType, resumeUpdatePageStatusType, QnAURL, resumeUpdatePageUrl]);

  if (viewMode !== VIEW_MODE.PC) return null;

  const clickUrl = (menu: IMyMenu) => {
    const { title, url, urlType } = menu;
    if (isEdit || isPreview) {
      switch (title) {
        case MY_BUTTON_TEXT.MY_PAGE[languageType]:
          return setShowMyPageModal(true);
        case MY_BUTTON_TEXT.RESUME_UPDATE[languageType]:
          return setShowResumeUpdateModal(true);
        case MY_BUTTON_TEXT.QNA[languageType]:
          return handleUrlClick({ url, isExternal: urlType === 'EXTERNAL', isPreview });
        default:
          return;
      }
    }

    handleUrlClick({ url, isExternal: urlType === 'EXTERNAL', isPreview });
  };

  const getEl = () => {
    switch (`MENU_COUNT_${myMenu.length}`) {
      case 'MENU_COUNT_0':
        return <div className={cx('listItem', { minWidth: !useLanguageSetting })} />;
      case 'MENU_COUNT_1':
        const menu = myMenu[0];
        const { title } = menu;
        return (
          <div className={cx('listItem', { minWidth: !useLanguageSetting })}>
            <li
              className={cx('link', { DARK: checkIsDarkMode(backgroundColor) })}
              style={{ color }}
              onMouseDown={(e) => e.stopPropagation()}
              onClick={() => clickUrl(menu)}
            >
              <Icon name={'person_line'} size={24} color={color} />
              <p>{title}</p>
            </li>
          </div>
        );
      case 'MENU_COUNT_2':
      default:
        return (
          <span className={cx('listItem', 'FOLDER', { minWidth: !useLanguageSetting })}>
            <li
              className={cx(
                'link',
                { 'ignore-hover': isDropdownMouseOver },
                { DARK: checkIsDarkMode(backgroundColor) }
              )}
              style={{ color }}
            >
              <Icon name={'person_line'} size={24} color={color} />
              <p>{MY_BUTTON_TEXT.TOTAL[languageType]}</p>
              <ul className={cx('depth-2')} ref={dropdownRef} onMouseDown={(e) => e.stopPropagation()}>
                {myMenu.map((menu) => {
                  const { title } = menu;
                  return (
                    <div key={title} className={cx('subMenu')} onClick={() => clickUrl(menu)}>
                      <li>{title}</li>
                    </div>
                  );
                })}
              </ul>
            </li>
          </span>
        );
    }
  };

  return (
    <>
      {getEl()}
      <ModalPreviewMyPage show={showMyPageModal} close={() => setShowMyPageModal(false)} />
      <ModalPreviewResumeUpdate show={showResumeUpdateModal} close={() => setShowResumeUpdateModal(false)} />
    </>
  );
};

export default HeaderMyButton;
