import { useRef, useState } from 'react';
import useOnClickOutside from '../../hook/useOnClickOutside';

interface IPopoverPosition {
  top: number;
  left: number;
  zIndex?: number;
  minWidth?: number;
}

const useListPopover = (capturing = false) => {
  const [popoverPosition, setPopoverPosition] = useState<IPopoverPosition | null>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const clickAreaRef = useRef<HTMLDivElement>(null);
  const optionsRef = useRef<HTMLDivElement>(null);
  const [isListMounted, setIsListMounted] = useState(false);

  useOnClickOutside(
    optionsRef,
    () => {
      setPopoverPosition(null);
    },
    capturing
  );

  const handleClickArea = () => {
    if (popoverPosition) {
      setPopoverPosition(null);
    }
  };

  const closeThenFocus = () => {
    setPopoverPosition(null);
    clickAreaRef.current?.focus();
  };

  return {
    closeThenFocus,
    popoverPosition,
    setPopoverPosition,
    clickAreaRef,
    wrapperRef,
    handleClickArea,
    optionsRef,
    isListMounted,
    setIsListMounted,
  };
};

export default useListPopover;
