// Created by kdw0601 on 2022-12-27
import { ForwardedRef, forwardRef, HTMLAttributes, useEffect } from 'react';
import styles from './List.module.scss';
import classNames from 'classnames/bind';
import ListItem from './ListItem';
import IListOption from './IListOption';

const cx = classNames.bind(styles);

export type MultipleVariant = `${'check'}${'' | '-sub'}`;
export type SingleVariant = 'sub' | `${'radio' | 'icon'}${'' | '-sub'}`;
export type CustomListItemProps<T, CustomData = unknown> = {
  onClick: (val: T) => void;
  option: IListOption<T, CustomData>;
  value?: T | T[];
};

export interface IListProps<T, CustomData = unknown> extends Omit<HTMLAttributes<HTMLDivElement>, 'onChange'> {
  options: IListOption<T, CustomData>[];
  value?: T | T[];
  variant?: SingleVariant | MultipleVariant;
  onChange?: (val: T) => void;
  onMount?: () => void;
  onUnmount?: () => void;
  customItem?: (props: CustomListItemProps<T, CustomData>) => JSX.Element | null;
  customItemHeight?: number;
  scrollAfterNItems?: number;
  color?: 'default' | 'dark';
}

/**
 * 명세서: {@link https://midasitweb-jira.atlassian.net/wiki/spaces/ATS/pages/3624337673/List}
 */
const List = <T, CustomData = unknown>(props: IListProps<T, CustomData>, ref: ForwardedRef<HTMLDivElement>) => {
  const {
    options,
    className,
    style,
    onMount,
    onUnmount,
    variant,
    customItemHeight,
    customItem: CustomItem,
    scrollAfterNItems = variant?.endsWith('sub') ? 4 : 6,
    color = 'default',
  } = props;
  useEffect(() => {
    onMount?.();
    return () => {
      onUnmount?.();
    };
  }, []);

  const handleClickOption = (clickedValue: T) => {
    props.onChange?.(clickedValue);
  };

  const isScrollNeeded = scrollAfterNItems < 1 ? false : options.length > scrollAfterNItems;

  return (
    <div className={cx('container', color, className)} style={style} ref={ref} data-designsystem>
      <div
        className={cx('list-wrapper', 'modal-scroll', variant?.includes('sub') && 'sub')}
        style={
          isScrollNeeded
            ? {
                maxHeight: 20 + scrollAfterNItems * (customItemHeight ?? (variant?.endsWith('sub') ? 52 : 32)),
                overflowY: 'scroll',
                paddingRight: 6,
              }
            : undefined
        }
      >
        {options.map((option, i) =>
          CustomItem ? (
            <CustomItem key={i} onClick={handleClickOption} option={option} value={props.value} />
          ) : (
            <ListItem
              key={i}
              {...option}
              variant={props.variant}
              onClick={handleClickOption}
              selected={
                Array.isArray(props.value)
                  ? props.value.includes(option.value)
                  : !option.subMenu && props.value === option.value
              }
            />
          )
        )}
        {!options.length && (
          <div className={cx('item-wrapper')}>
            <button className={cx('item')} disabled>
              <div className={cx('name-wrap')}>
                <div className={cx('name')}>(항목 없음)</div>
              </div>
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default forwardRef(List) as <T, CustomData = unknown>(
  props: IListProps<T, CustomData> & { ref?: ForwardedRef<HTMLDivElement> }
) => JSX.Element;
