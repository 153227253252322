import Modal from '../../../context/modal/Modal';
import ModalFooter from '../../../context/modal/ModalFooter';
import ModalHeader from '../../../context/modal/ModalHeader';
import classnames from 'classnames/bind';
import styles from './ModalPreviewResumeUpdate.module.scss';
import Image from 'next/image';
import resume_update_preview from 'ui/public/img/editor/resume_update_preview.png';
const cx = classnames.bind(styles);

interface IProps {
  show: boolean;
  close: () => void;
}

const ModalPreviewResumeUpdate = ({ show, close }: IProps) => {
  return (
    <Modal show={show} close={close} pressEnter={close}>
      <ModalHeader>미리보기</ModalHeader>
      <div className={cx('modal-body')}>
        <p>지원자들은 해당 페이지로 지원서를 수정할 수 있어요.</p>
        <div className={cx('preview-image-wrapper')}>
          <Image src={resume_update_preview} alt={'resume_update_preview'} width={832} height={418} />
        </div>
      </div>
      <ModalFooter onClick={close} btnTitle={'확인'}></ModalFooter>
    </Modal>
  );
};

export default ModalPreviewResumeUpdate;
