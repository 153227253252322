import { RefObject, useEffect, useState } from 'react';
import { ViewModeType } from '../interface/common/ViewMode';

const MAX_TABLET_WIDTH = 1279;
const MAX_MOBILE_WIDTH = 768;

/** 윈도우 창 크기 조절 시 contentWrap width 를 보고 뷰 모드 변경 **/

interface IUseViewModeProps {
  initViewMode?: ViewModeType;
  ref: RefObject<HTMLDivElement>;
}

const useViewMode = ({ initViewMode, ref }: IUseViewModeProps) => {
  const [viewMode, setViewMode] = useState<ViewModeType | undefined>(initViewMode);
  const isViewMode = (type: ViewModeType) => type === viewMode;
  const isPCView = (width: number) => width > MAX_TABLET_WIDTH;
  const isTabletView = (width: number) => width > MAX_MOBILE_WIDTH && width <= MAX_TABLET_WIDTH;
  const isMobileView = (width: number) => width <= MAX_MOBILE_WIDTH;

  const handleResize = () => {
    if (!ref.current) return;
    const width = ref.current.clientWidth;
    if (isPCView(width)) setViewMode('PC');
    if (isTabletView(width)) setViewMode('TABLET');
    if (isMobileView(width)) setViewMode('MOBILE');
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [ref.current, handleResize]);

  return {
    viewMode,
    setViewMode,
    isViewMode,
    handleResize,
  };
};

export default useViewMode;
