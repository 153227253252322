import { useState } from 'react';
import classNames from 'classnames/bind';
import Modal from '../../context/modal/Modal';
import ModalHeader from 'ui/context/modal/ModalHeader';
import ModalFooter from 'ui/context/modal/ModalFooter';
import style from './PrivacyPolicyModal.module.scss';
import { IPrivacyPolicy, IPrivacyPolicyItem } from '../../interface/footer/IFooterLink';
import Dropdown from '../../common/dropdown/Dropdown';
import Paragraph from '../../common/paragraph/Paragraph';
import { UseQueryResult } from '@tanstack/react-query';
import useWindowResize from '../../hook/useWindowResize';

const cx = classNames.bind(style);

interface IProps {
  show: boolean;
  policyList: IPrivacyPolicy[];
  close: () => void;
  confirm: () => void;
  querySelectedPolicy?: (policySn: string, endabled: boolean) => UseQueryResult<IPrivacyPolicyItem, unknown>;
}

const PrivacyPolicyModal = ({ show, policyList, close, confirm, querySelectedPolicy }: IProps) => {
  const [selectedPolicy, setSelectedPolicy] = useState(policyList.length ? policyList[0].privacyPolicySn : '');
  const res = querySelectedPolicy && querySelectedPolicy(selectedPolicy, !!selectedPolicy);
  const selectOptions = policyList.map((el) => ({ name: el.title, value: el.privacyPolicySn }));
  const { width: windowWidth, scrollBarWidth } = useWindowResize({ debounceMs: 50 });

  if (!policyList.length || !res || !res.data) return <></>;
  const { title, contents } = res.data;

  const privacyPolicyDom = new DOMParser().parseFromString(contents, 'text/html');

  const getPrivacyPolicyBodyWidth = (document: Document) => {
    const MIN_WIDTH = 716; // 모달 body의 최소 너비
    const PADDING = 16; // 모달 body의 padding
    const SCROLL_WIDTH = 20;
    const elements = document.querySelectorAll<HTMLElement>('*');
    let maxWidth = MIN_WIDTH;

    elements.forEach((element) => {
      const elementWidth = element.style.width;
      if (!elementWidth) return;

      const width = parseFloat(elementWidth.replace('px', ''));
      if (width > maxWidth) maxWidth = width;
    });

    const breakPoint = (windowWidth - scrollBarWidth) * 0.8;
    if (breakPoint < maxWidth + PADDING * 2) {
      return breakPoint - SCROLL_WIDTH;
    }
    return maxWidth + PADDING * 2 + SCROLL_WIDTH;
  };

  const bodyWidth = getPrivacyPolicyBodyWidth(privacyPolicyDom);

  return (
    <>
      <Modal contentClassName={cx('modal-container')} show={show} close={close} pressEnter={confirm}>
        <ModalHeader>개인정보 처리방침</ModalHeader>
        <div className={cx('modal-body')}>
          <div className={cx('policy-header')} style={{ width: `${bodyWidth}px` }}>
            <Paragraph variant={'B2'} bold>
              {title}
            </Paragraph>
            <Dropdown
              size={'sm'}
              className={cx('policy-dropdown')}
              options={selectOptions}
              value={selectedPolicy}
              onChange={(val) => setSelectedPolicy(val)}
            />
          </div>
          <div
            className={cx('policy-content')}
            dangerouslySetInnerHTML={{ __html: contents ?? '' }}
            style={{ width: `${bodyWidth}px` }}
          />
        </div>
        <ModalFooter btnTitle={'확인'} onClick={confirm} />
      </Modal>
    </>
  );
};

export default PrivacyPolicyModal;
