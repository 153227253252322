import { ComponentPropsWithoutRef, FC } from 'react';
import classNames from 'classnames/bind';
import style from './Paragraph.module.scss';
import { COLORS } from '../../constants/colors';

const cx = classNames.bind(style);

interface IProps extends ComponentPropsWithoutRef<'p'> {
  variant: 'B1' | 'B2' | 'B3' | 'B4';
  bold?: boolean;
  className?: string;
}

const Paragraph: FC<IProps> = ({ children, variant, bold = false, className = '' }) => {
  return <p className={cx('paragraph', `variant-${variant}`, bold && 'bold', className)}>{children}</p>;
};
export default Paragraph;
