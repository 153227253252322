import useToast from './useToast';

export const WARNING_TOAST_MESSAGE = {
  DEFAULT: '잘못된 접근입니다.',
  PREVIEW_DISABLED: '미리보기에서는 확인할 수 없습니다.',
  MOBILE_PREVIEW_DISABLED: '모바일 미리보기에서는 확인할 수 없습니다.',
};

const useWarningToast = () => {
  const { setToast } = useToast();

  const setWarningToast = (text = WARNING_TOAST_MESSAGE['DEFAULT']) => {
    setToast({
      icon: 'WARNING',
      text,
    });
  };

  const setPreviewDisabledWarningToast = () => setWarningToast(WARNING_TOAST_MESSAGE['PREVIEW_DISABLED']);
  const setMobilePreviewDisabledWarningToast = () => setWarningToast(WARNING_TOAST_MESSAGE['MOBILE_PREVIEW_DISABLED']);

  return { setWarningToast, setPreviewDisabledWarningToast, setMobilePreviewDisabledWarningToast };
};

export default useWarningToast;
