import React, { useState } from 'react';
import classnames from 'classnames/bind';
import {
  HIDE_TYPE,
  IFolderLNBType,
  ILNBType,
  IMyPageLNBType,
  IMyPageMenuType,
  ISingleLNBType,
  MyMenuType,
  URL_TYPE,
} from '../../../interface/header/IMenu';
import useUrlRouter from '../../../hook/useUrlRouter';
import Icon from '../../../common/icon/Icon';
import { COLORS } from '../../../constants/colors';
import { ReadonlyURLSearchParams, usePathname, useSearchParams } from 'next/navigation';
import style from '../ViewHeader.module.scss';
import useWarningToast from '../../../context/toast/useWarningToast';
import { SOLUTION_PATH_PREFIX, SOLUTION_TYPE } from '../../../util/solution';
import { HasOption } from '../../../interface/common/hasOption';
import { LANGUAGE } from '../../../constants/language';
import { useUrl } from '../../../hook/useUrl';

const cx = classnames.bind(style);

interface IFolderProps {
  title: string;
  subMenu: ILNBType<IFolderLNBType> | ILNBType<IMyPageLNBType>;
  isPreview?: boolean;
  isEdit?: boolean;
  closeDrawer?: () => void;
}

const DrawerFolder = ({ title, subMenu, isPreview, isEdit, closeDrawer, options }: IFolderProps & HasOption) => {
  const [isOpen, setIsOpen] = useState(false);
  const { handleUrlClick } = useUrlRouter();
  const { setMobilePreviewDisabledWarningToast } = useWarningToast();
  const { get } = useSearchParams() as ReadonlyURLSearchParams;
  const { buildUrl } = useUrl();
  const pathname = usePathname();
  const languageType = options && options['languageType'];

  const iconName = isOpen ? 'arrow_top_line' : 'arrow_bottom_line';

  const listItemEls = subMenu.subMenuList.map((subMenu) => {
    const isExternal = () => {
      switch (subMenu.formType) {
        case 'SINGLE':
          return subMenu.page.urlType === URL_TYPE.EXTERNAL;
        case 'MY_PAGE':
        case 'QNA':
          return true;
        case 'RESUME_UPDATE':
          return false;
        default:
          return false;
      }
    };

    const isCurrentPage = () => {
      switch (subMenu.formType) {
        case 'SINGLE':
          if (isEdit || isPreview) return Number(get('pageSn')) === subMenu.page.pageSn;
          return pathname && pathname.includes(SOLUTION_PATH_PREFIX[`${SOLUTION_TYPE}`](subMenu.page.url));
        case 'QNA':
          return get('viewType') === 'QnA';
        case 'RESUME_UPDATE':
          return get('viewType') === 'ResumeUpdate';
        default:
          return false;
      }
    };

    const handleClickSubMenu = {
      SINGLE: () => {
        const {
          page: { pageSn, url, urlType },
        } = subMenu as ILNBType<ISingleLNBType>;
        const isExternal = urlType === URL_TYPE.EXTERNAL;
        setIsOpen(false);

        if (isEdit) {
          handleUrlClick({
            url: isExternal
              ? url
              : buildUrl('/editor/setting')
                  .withSearchParam('designSn', get('designSn'))
                  .withSearchParam('pageSn', pageSn)
                  .withSearchParam('languageType', languageType !== LANGUAGE.KOR ? languageType : null)
                  .getResult(),
            isExternal,
            isPreview,
          });
          closeDrawer && closeDrawer();
          return;
        }

        if (isPreview) {
          handleUrlClick({
            url: isExternal
              ? url
              : buildUrl(`${pathname}`)
                  .withSearchParam('designSn', get('designSn'))
                  .withSearchParam('pageSn', pageSn)
                  .withSearchParam('languageType', languageType !== LANGUAGE.KOR ? languageType : null)
                  .getResult(),
            isExternal,
            isPreview,
          });
          closeDrawer && closeDrawer();
          return;
        }

        handleUrlClick({ url, isExternal, isPreview });
        closeDrawer && closeDrawer();
      },
      MY_PAGE: (e: React.MouseEvent) => {
        if (isPreview) {
          e.preventDefault();
          return setMobilePreviewDisabledWarningToast();
        }
        const { url } = subMenu as IMyPageMenuType;
        handleUrlClick({ url: url, isExternal: isExternal(), isPreview });
      },
      QNA: () => {
        if (get('viewType') === 'QnA') return;
        const url = new URL(window.location.href);
        const searchParams = new URLSearchParams(url.search);
        searchParams.set('viewType', 'QnA');
        if (isPreview) {
          handleUrlClick({
            url: `${window.location.pathname}?${searchParams.toString()}`,
            isExternal: false,
            isPreview,
          });
          closeDrawer && closeDrawer();
          return;
        }

        handleUrlClick({
          url: `?${searchParams.toString()}`,
          isExternal: false,
          isPreview,
        });
        closeDrawer && closeDrawer();
      },
      RESUME_UPDATE: (e: React.MouseEvent) => {
        if (isPreview) {
          e.preventDefault();
          return setMobilePreviewDisabledWarningToast();
        }
        if (get('viewType') === 'ResumeUpdate') return;
        const url = new URL(window.location.href);
        const searchParams = new URLSearchParams(url.search);
        searchParams.set('viewType', 'ResumeUpdate');
        handleUrlClick({
          url: `?${searchParams.toString()}`,
          isExternal: false,
          isPreview,
        });
        closeDrawer && closeDrawer();
      },
    };

    if (subMenu.hideType !== HIDE_TYPE.VISIBLE) return null;

    return (
      <div
        key={subMenu.menuSn}
        className={cx('subMenu', { active: isCurrentPage() })}
        onClick={(e) => {
          handleClickSubMenu[subMenu.formType as 'SINGLE' | 'MY_PAGE' | 'QNA' | 'RESUME_UPDATE'](e);
        }}
      >
        <li>{subMenu.title}</li>
        {isExternal() && <Icon className={cx('icon')} name={'arrow_right_line'} size={24} color={COLORS.gray900} />}
      </div>
    );
  });

  return (
    <div className={cx('listItem')}>
      <li className={cx('link')}>
        <div className={cx('titleWrap')} onClick={() => setIsOpen((prev) => !prev)}>
          {title}
          <Icon className={cx('icon')} name={iconName} size={24} color={COLORS.gray900} />
        </div>

        <ul className={cx('depth-2', { open: isOpen })}>{listItemEls}</ul>
      </li>
    </div>
  );
};

export default DrawerFolder;
