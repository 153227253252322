import { atom, selector } from 'recoil';
import { ViewModeType } from 'ui/interface/common/ViewMode';

export const viewModeAtom = atom<ViewModeType>({
  key: '@page/viewMode',
  default: 'PC',
});

export const isPcAtom = selector<boolean>({
  key: '@page/isPC',
  get: ({ get }) => {
    return get(viewModeAtom) === 'PC';
  },
});

export const isTabletAtom = selector<boolean>({
  key: '@page/isTablet',
  get: ({ get }) => {
    return get(viewModeAtom) === 'TABLET';
  },
});

export const isMobileAtom = selector<boolean>({
  key: '@page/isMobile',
  get: ({ get }) => {
    return get(viewModeAtom) === 'MOBILE';
  },
});
