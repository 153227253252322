import { useState } from 'react';

export type ConfirmType = {
  title: string;
  description: string;
  confirmTitle?: string;
  cancelTitle?: string;
  confirmClick: (closeConfirm: () => void) => void;
  cancelClick?: (closeConfirm: () => void) => void;
};

export type ConfirmContextValueType = {
  confirm: ConfirmType | null;
  setConfirm: (confirm: ConfirmType | null) => void;
};

export const initialConfirmContextValue: ConfirmContextValueType = {
  confirm: null,
  setConfirm: () => null,
};

const useConfirmContextValue = () => {
  const [confirm, setConfirmState] = useState<ConfirmType | null>(null);

  const setConfirm = (confirm: ConfirmType | null) => {
    setConfirmState(confirm);
  };

  return {
    confirm,
    setConfirm,
  };
};

export default useConfirmContextValue;
