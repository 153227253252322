import IViewLayout from 'ui/interface/layout/IViewLayout';
import IViewBlock from 'ui/interface/block/IViewBlock';
import IViewGroup from 'ui/interface/group/IViewGroup';
import IViewContents from 'ui/interface/contents/IViewContents';
import { IContentsType } from 'ui/interface/contents/IContents';
import { GroupIdType } from 'ui/interface/group/IGroup';
import { BlockIdType } from 'ui/interface/block/IBlock';
import { LayoutIdType } from 'ui/interface/layout/ILayout';
import IViewPage from 'ui/interface/page/IViewPage';
import { IStorageDto } from '../interface/IStorageDto';
import { IAccordionValue, IAccordionValueDTO } from '../interface/contents/accordion/IAccordion';

const useViewPage = () => {
  const getViewPageWithResponse: (storage: IStorageDto) => IViewPage = (storage: IStorageDto) => {
    const { page, layout, block, group, contents } = storage;

    const getViewContents: (groupId: GroupIdType) => IViewContents<IContentsType>[] = (groupId) => {
      return contents
        .filter((contents) => contents.parentId === groupId)
        .map((contents) =>
          contents.type === 'ACCORDION'
            ? {
                ...contents,
                value: {
                  ...(contents.value as unknown as IAccordionValueDTO),
                  accordionList: (contents.value as unknown as IAccordionValueDTO).accordionList.map(
                    (accordionItem) => ({
                      ...accordionItem,
                      title: JSON.parse(accordionItem.title),
                      body: JSON.parse(accordionItem.body),
                    })
                  ),
                },
              }
            : contents
        );
    };

    const getViewGroup: (blockId: BlockIdType) => IViewGroup[] = (blockId) => {
      return group
        .filter((group) => group.parentId === blockId)
        .map((group) => {
          return {
            ...group,
            contents: getViewContents(group.id),
          };
        });
    };

    const getViewBlock: (layoutId: LayoutIdType) => IViewBlock[] = (layoutId) => {
      return block
        .filter((block) => block.parentId === layoutId)
        .map((block) => {
          return {
            ...block,
            groups: getViewGroup(block.id),
          };
        });
    };

    const getViewLayout: () => IViewLayout[] = () => {
      return layout.map((layout) => {
        return {
          ...layout,
          blocks: getViewBlock(layout.id),
        };
      });
    };

    return { id: page.id, style: page.style, layouts: getViewLayout() };
  };

  return {
    getViewPageWithResponse,
  };
};

export default useViewPage;
