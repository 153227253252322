import React, { FC, useEffect, useState } from 'react';
import classnames from 'classnames/bind';
import styles from './TopButton.module.scss';
import Icon from '../../common/icon/Icon';
import useScrollTop from '../../hook/useScrollTop';
import { COLORS } from '../../constants/colors';
const cx = classnames.bind(styles);

interface IProps {
  needMovePosition?: boolean;
}

const TopButton: FC<IProps> = ({ needMovePosition = false }) => {
  const { scrollTop } = useScrollTop({});
  const [isMobile, setIsMobile] = useState<boolean>(false);

  const handleScrollTop = () => window.scrollTo(0, 0);

  useEffect(() => {
    if (navigator.userAgent.match(/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i)) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, []);

  return (
    <button
      className={cx('btnTop', { 'need-move': needMovePosition }, { mobile: isMobile }, { visible: scrollTop !== 0 })}
      role={'button'}
      onClick={handleScrollTop}
    >
      <Icon name={'arrow_upward_line'} size={isMobile ? 30 : 36} color={COLORS.white} />
    </button>
  );
};

export default TopButton;
