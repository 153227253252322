import Modal from '../../../context/modal/Modal';
import ModalFooter from '../../../context/modal/ModalFooter';
import ModalHeader from '../../../context/modal/ModalHeader';
import classnames from 'classnames/bind';
import styles from './ModalPreviewMyPage.module.scss';
import Image from 'next/image';
import my_page_preview from 'ui/public/img/editor/my_page_preview.png';
const cx = classnames.bind(styles);

interface IProps {
  show: boolean;
  close: () => void;
}

const ModalPreviewMyPage = ({ show, close }: IProps) => {
  return (
    <Modal show={show} close={close} pressEnter={close}>
      <ModalHeader>미리보기</ModalHeader>
      <div className={cx('modal-body')}>
        <p>지원자들은 마이페이지로 지원현황 및 전형 결과를 확인할 수 있어요.</p>
        <div className={cx('preview-image-wrapper')}>
          <Image src={my_page_preview} alt={'my_page_preview'} width={832} height={465} />
        </div>
      </div>
      <ModalFooter onClick={close} btnTitle={'확인'}></ModalFooter>
    </Modal>
  );
};

export default ModalPreviewMyPage;
