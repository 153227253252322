'use client';

import classnames from 'classnames/bind';
import Portal from '../../common/portal/Portal';
import style from './Modal.module.scss';
import { FC } from 'react';
import useModalContextValue from './useModalContextValue';
import useBlockBodyScrollingInModal from './useBlockBodyScrollingInModal';
import useModalEvent from './useModalEvent';

const cx = classnames.bind(style);

// Modal을 사용하는 컴포넌트 내에 input과 state가 있는 경우 모달이 꺼졌을 때 input값 초기화를 위해,
// <ModalExample key={`${show}`} ...> key를 넣어주어 초기화

interface IProps {
  children: React.ReactNode;
  contentClassName?: string;
  confirm?: boolean; ///////////// Confirm 모달 여부
  dim?: boolean; ///////////////// 배경에 dim 처리 여부
  isDialog?: boolean; //////////// 배경 클릭시 닫히지 않는 모달 여부
  ignoreCountModal?: boolean; //// 모달 카운트 무시
  show?: boolean; //////////////// 모달 show 여부
  pressEnter?: () => void; /////// 모달에서 엔터키 눌렀을 때
  close: () => void;
}

const Modal: FC<IProps> = (props) => {
  const { show = true } = props;
  if (!show) return null;

  return <ModalInner {...props} />;
};

const ModalInner: FC<IProps> = (props) => {
  const {
    children,
    contentClassName,
    confirm = false,
    dim = true,
    isDialog = false,
    ignoreCountModal = false,
    pressEnter,
    close,
  } = props;

  useModalContextValue();
  useBlockBodyScrollingInModal({ top: window.scrollY, ignoreCountModal });
  const { contentRef } = useModalEvent({ isDialog, close, pressEnter });

  const cancelMouseEvent = (e: React.MouseEvent<HTMLDivElement>) => {
    !isDialog && e.stopPropagation();
  };

  return (
    <Portal domId={'modal-root'}>
      <div
        className={cx('container', { confirm }, { dim })}
        data-testid={'modal'}
        onClick={cancelMouseEvent}
        onMouseDown={cancelMouseEvent}
      >
        <div ref={contentRef} className={cx('content', contentClassName)}>
          {children}
        </div>
      </div>
    </Portal>
  );
};

export default Modal;
