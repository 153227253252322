import { OFF, ON } from './common';

export const FOOTER_MENU = {
  NORMAL: 'NORMAL',
  PRIVACY_POLICY: 'PRIVACY_POLICY',
  EMAIL_CRAWLING_REJECT: 'EMAIL_CRAWLING_REJECT',
} as const;
export type FooterMenuOptionType = keyof typeof FOOTER_MENU;

export const FOOTER_MENU_OPTIONS = [
  { value: FOOTER_MENU.EMAIL_CRAWLING_REJECT, name: '이메일무단수집거부' },
  { value: FOOTER_MENU.PRIVACY_POLICY, name: '개인정보 처리방침' },
  { value: FOOTER_MENU.NORMAL, name: '일반 메뉴' },
] as const;

export const FOOTER_UNIQUE_MENU = [FOOTER_MENU.EMAIL_CRAWLING_REJECT, FOOTER_MENU.PRIVACY_POLICY];

export const FOOTER_LINK = {
  PAGE: 'PAGE',
  URL: 'URL',
  MODAL: 'MODAL',
} as const;
export type FooterLinkType = keyof typeof FOOTER_LINK;

export const FOOTER_MENU_DEFAULT_VALUE = {
  [FOOTER_MENU.NORMAL]: {
    menuType: FOOTER_MENU.NORMAL,
    title: '메뉴명',
    linkType: FOOTER_LINK.URL,
    url: '',
    boldType: OFF,
    highlightStatus: OFF,
  },
  [FOOTER_MENU.PRIVACY_POLICY]: {
    menuType: FOOTER_MENU.PRIVACY_POLICY,
    title: '개인정보 처리방침',
    linkType: FOOTER_LINK.MODAL,
    boldType: ON,
    highlightStatus: OFF,
  },
  [FOOTER_MENU.EMAIL_CRAWLING_REJECT]: {
    menuType: FOOTER_MENU.EMAIL_CRAWLING_REJECT,
    title: '이메일무단수집거부',
    linkType: FOOTER_LINK.MODAL,
    boldType: OFF,
    highlightStatus: OFF,
  },
};
