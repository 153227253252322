import styles from './TextFieldBase.module.scss';
import classNames from 'classnames/bind';
import { TextFieldBaseProps, sizeType } from './ITextField';
import { forwardRef } from 'react';
import Icon from '../icon/Icon';
import { COLORS } from '../../constants/colors';

const cx = classNames.bind(styles);

const TextFieldBase = forwardRef<HTMLInputElement, TextFieldBaseProps>(function TextFieldBase(
  {
    size = 'sm',
    leftIcon,
    beforeInput,
    afterInput,
    errMessage,
    className,
    innerClassName,
    error,
    textAlign = 'left',
    ellipsis = false,
    ...htmlAttr
  },
  ref
) {
  const { disabled, readOnly } = htmlAttr;

  return (
    <div className={cx('container', className)}>
      <div
        className={cx(
          'wrapper',
          innerClassName,
          size,
          { disabled: disabled },
          { error: error },
          { readonly: readOnly },
          { icon: !!leftIcon }
        )}
      >
        {leftIcon && (
          <Icon
            name={leftIcon?.name ?? 'search_line'}
            size={leftIcon?.size ?? (size === 'lg' ? 24 : 18)}
            color={leftIcon?.color ?? COLORS.gray600}
          />
        )}
        {beforeInput}
        <input className={cx('input', textAlign, { ellipsis })} ref={ref} {...htmlAttr} />
        {afterInput}
      </div>
      {error && errMessage && <p className={cx({ error: errMessage })}>{errMessage}</p>}
    </div>
  );
});

export default TextFieldBase;
