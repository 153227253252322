import { useEffect, useRef, useState } from 'react';

const useHoverEffect = <T extends HTMLElement>() => {
  const [mouseOver, setMouseOver] = useState(false);

  const ref = useRef<T>(null);

  useEffect(() => {
    if (!ref?.current) return;
    ref.current.addEventListener('mouseover', () => setMouseOver(true));
    ref.current.addEventListener('mouseleave', () => setMouseOver(false));
  }, []);

  return {
    mouseOver,
    ref,
  };
};

export default useHoverEffect;
