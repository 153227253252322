import { ICompanyLogo } from '../../interface/header/ICompanyLogo';
import classnames from 'classnames/bind';
import styles from './ViewHeader.module.scss';
import { getImageURL } from 'ats-editor/src/utils/common/file.utils';
import { ILNBType, ISingleLNBType } from '../../interface/header/IMenu';
import Icon from '../../common/icon/Icon';
import { COLORS } from '../../constants/colors';
import Paragraph from '../../common/paragraph/Paragraph';
import useUrlRouter, { URL_PATH } from '../../hook/useUrlRouter';
import { ReadonlyURLSearchParams, useSearchParams } from 'next/navigation';
import { HasOption } from '../../interface/common/hasOption';
import { LANGUAGE } from '../../constants/language';
import { useUrl } from '../../hook/useUrl';
const cx = classnames.bind(styles);

const Logo = ({
  companyLogo,
  main,
  isPreview,
  isEdit,
  darkMode,
  className,
  handleCloseMobileDrawer,
  options,
  title,
}: {
  companyLogo: ICompanyLogo;
  main: ILNBType<ISingleLNBType>;
  darkMode: boolean;
  isPreview?: boolean;
  isEdit?: boolean;
  className?: string;
  handleCloseMobileDrawer: () => void;
  title?: string;
} & HasOption) => {
  const { get } = useSearchParams() as ReadonlyURLSearchParams;
  const { handleUrlClick } = useUrlRouter();
  const companyLogoImgUrl = getImageURL({ fileUid: companyLogo.logoFileUid, fileUrl: companyLogo.logoFileUrl });
  const { companyName, getPathName, findPageTitle } = options?.pageMetadataHooks ?? {
    companyName: undefined,
    getPathName: undefined,
    findPageTitle: undefined,
  };
  const { buildUrl, getSearchParam } = useUrl();
  const languageType = options ? options['languageType'] : LANGUAGE.KOR;

  const getAltText = () => {
    if (!companyName || !getPathName || !findPageTitle) return '';
    return `${companyName}의 ${findPageTitle(main.page.url)}와(과) 연결되었습니다.`;
  };

  const handleClick = () => {
    const { urlType, pageSn, url } = main.page;
    const isExternal = urlType === 'EXTERNAL';
    handleCloseMobileDrawer();

    if (isEdit) {
      return handleUrlClick({
        url: buildUrl('/editor/setting')
          .withSearchParam('designSn', get('designSn'))
          .withSearchParam('pageSn', pageSn)
          .withSearchParam('languageType', languageType !== LANGUAGE.KOR ? languageType : null)
          .getResult(),
        isExternal,
        isPreview,
      });
    }

    if (isPreview) {
      const pathname = window.location.pathname;
      if (pathname === '/editor/preview') {
        return handleUrlClick({
          url: buildUrl('/editor/preview')
            .withSearchParam('designSn', get('designSn'))
            .withSearchParam('pageSn', pageSn)
            .withSearchParam('languageType', languageType !== LANGUAGE.KOR ? languageType : null)
            .getResult(),
          isExternal,
          isPreview,
        });
      }

      if (pathname === '/template-preview') {
        return handleUrlClick({
          url: buildUrl('/template-preview')
            .withSearchParam('pageSn', pageSn)
            .withSearchParam('templateSn', getSearchParam('templateSn'))
            .getResult(),
          isExternal,
          isPreview,
        });
      }
    }

    return handleUrlClick({
      url: isExternal
        ? url
        : buildUrl(`${url}`)
            .withSearchParam('languageType', languageType !== LANGUAGE.KOR ? languageType : null)
            .getResult(),
      isExternal,
    });
  };

  return (
    <>
      <a href={'#'} onClick={handleClick} className={cx(className)}>
        {companyLogoImgUrl ? (
          <img className={cx('image-content', companyLogo.logoType)} src={`${companyLogoImgUrl}`} alt={getAltText()} />
        ) : (
          <div className={cx('default-logo', { darkMode })}>
            <Icon name={'home_line'} size={24} color={darkMode ? COLORS.white : COLORS.gray800} />
            <Paragraph className={cx('default-logo-text')} variant={'B2'} bold>
              HOME
            </Paragraph>
          </div>
        )}
      </a>
      <h1 className={cx('company-name')}>{title}</h1>
    </>
  );
};

export default Logo;
