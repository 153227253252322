// Created by kdw0601 on 2022-12-27
import { forwardRef, InputHTMLAttributes } from 'react';
import styles from './Radio.module.scss';
import classNames from 'classnames/bind';
import useForwardedRef from '../../hook/useForwardedRef';

const cx = classNames.bind(styles);

const Radio = forwardRef<HTMLInputElement, InputHTMLAttributes<HTMLInputElement>>(function Radio(props, ref) {
  const { className, checked, tabIndex, ...htmlAttr } = props;
  const inputRef = useForwardedRef(ref);

  const handleClick = () => {
    inputRef.current?.click();
  };

  return (
    <>
      <input type={'radio'} className={cx('input')} checked={checked} hidden ref={inputRef} {...htmlAttr} />
      <div
        tabIndex={tabIndex !== undefined ? tabIndex : htmlAttr.disabled ? undefined : 0}
        className={cx('radio', className)}
        onClick={handleClick}
      />
    </>
  );
});

export default Radio;
