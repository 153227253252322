export const ON = 'ON' as const;
export const OFF = 'OFF' as const;
export const ON_OFF = {
  [ON]: ON,
  [OFF]: OFF,
} as const;
export type OnOffType = keyof typeof ON_OFF;
export const toggleOnOff = (onOrOff: OnOffType) => (onOrOff === ON ? OFF : ON);
export const isOnOff = (onOrOff: OnOffType) => onOrOff === ON;

// 게시상태 항상 / 일반
export const ANNOUNCEMENT_TYPE = {
  NORMAL: 'NORMAL',
  ALWAYS: 'ALWAYS',
} as const;
export type TAnnouncementType = keyof typeof ANNOUNCEMENT_TYPE;

export const DEFAULT_PAGE = 1;
