'use client';

import { useEffect, useState } from 'react';
import debounce from '../util/debounce';

interface IProps {
  debounceMs?: number;
  onResize?: () => void;
}

const useWindowResize = (props?: IProps) => {
  const { debounceMs = 200, onResize } = props || {};
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [scrollBarWidth, setScrollBarWidth] = useState(0);

  const handleResize = debounce(() => {
    if (!window) return;
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
    setScrollBarWidth(window.innerWidth - document.documentElement.clientWidth);
  }, debounceMs);

  useEffect(() => {
    if (!window) return;
    setWidth(window?.innerWidth ?? 0);
    setHeight(window?.innerHeight ?? 0);
  }, []);

  useEffect(() => {
    handleResize();
    window?.addEventListener('resize', handleResize);
    return () => window?.removeEventListener('resize', handleResize);
  }, [handleResize]);

  useEffect(() => onResize?.(), [width, height, onResize]);

  return {
    width,
    height,
    scrollBarWidth,
  };
};

export default useWindowResize;
