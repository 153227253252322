import { useState } from 'react';

export type LoadingContextValueType = {
  loading: boolean;
  setLoading: (loading: boolean) => void;
};

export const initLoadingContextValue = {
  loading: false,
  setLoading: () => null,
};

const useLoadingContextValue = () => {
  const [loading, setLoading] = useState(false);

  return {
    loading,
    setLoading,
  };
};

export default useLoadingContextValue;
