import { OnOffType } from '../../constants/common';
import { UrlType } from '../header/IMenu';
import { Nullable } from './common';

export const LINK_TYPE = {
  PAGE: 'PAGE',
  URL: 'URL',
} as const;

export type LinkType = keyof typeof LINK_TYPE;

export interface PageUrlType {
  urlType: UrlType;
  url: string;
}

export type LinkStyle = {
  linkStatusType: OnOffType;
  linkType: LinkType;
  url: string;
  pageSn?: Nullable<number>;
  pageUrl: PageUrlType;
};
