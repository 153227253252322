import { useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';
import GlobalContext from '../GlobalContext';
import IToastMessageProps from './IToastMessageProps';

export const TOAST_MESSAGE = {
  UNKNOWN_ERROR: '서버가 불안정해요, 잠시 후 다시 시도해주세요.',
};

const useToast = () => {
  const {
    toast: { toastList, setToastList },
  } = useContext(GlobalContext);

  const setToast = (value: IToastMessageProps) => {
    setToastList((toast: any) => [
      ...toast,
      {
        key: uuidv4(),
        durationMillisec: 2000,
        ref: null,
        type: 'toast',
        bottomOffset: 24,
        ...value,
      },
    ]);
  };

  const setFailToast = (text?: string) => {
    setToast({
      icon: 'FAILED',
      text: text ? text : TOAST_MESSAGE['UNKNOWN_ERROR'],
    });
  };

  return {
    toastList,
    setToast,
    setFailToast,
  };
};

export default useToast;
