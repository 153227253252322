import { useState } from 'react';

export type AlertType = {
  title: string;
  description: string | React.ReactNode;
  btnTitle?: string;
  closeBtnTitle?: string;
  onClick?: (closeAlert: () => void) => void;
};

export type AlertContextValueType = {
  alert: AlertType | null;
  setAlert: (alert: AlertType | null) => void;
};

export const initialAlertContextValue: AlertContextValueType = {
  alert: null,
  setAlert: () => null,
};

const useAlertContextValue = () => {
  const [alertState, setAlertState] = useState<AlertType | null>(null);

  const setAlert = (alert: AlertType | null) => {
    setAlertState(alert);
  };

  return {
    alert: alertState,
    setAlert,
  };
};

export default useAlertContextValue;
