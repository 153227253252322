import styles from './Tag.module.scss';
import classNames from 'classnames/bind';
import { HTMLAttributes } from 'react';

const cx = classNames.bind(styles);

type TVariantGroup = 'primary' | 'secondary' | 'outlined' | 'custom';
type TColorGroup = 'teal' | 'green' | 'red' | 'gray' | 'blue' | 'dark';

export const TAG_COLOR: Record<string, TColorGroup> = {
  TEAL: 'teal',
  GREEN: 'green',
  RED: 'red',
  GRAY: 'gray',
  BLUE: 'blue',
  DARK: 'dark',
} as const;

interface IProps extends HTMLAttributes<HTMLElement> {
  variant: TVariantGroup;
  color?: TColorGroup;
  fontColor?: string;
  backgroundColor?: string;
  size?: 'lg' | 'sm';
  round?: boolean;
  bold?: boolean;
}

interface ITagType {
  (props: IProps): JSX.Element;

  Primary: (props: Omit<IProps, 'variant'>) => JSX.Element;
  Secondary: (props: Omit<IProps, 'variant'>) => JSX.Element;
  Outlined: (props: Omit<IProps, 'variant'>) => JSX.Element;
}

/**
 * 명세서: {@link https://midasitweb-jira.atlassian.net/wiki/spaces/ATS/pages/3632563552/Tag}
 */
const Tag: ITagType = (props: IProps) => {
  const {
    variant,
    backgroundColor,
    fontColor,
    color,
    size = 'lg',
    round = true,
    bold = false,
    className,
    children,
    ...htmlAttr
  } = props;

  return (
    <span
      style={{ color: fontColor, backgroundColor }}
      {...htmlAttr}
      className={cx('tag', variant, color, round && 'round', bold && 'bold', size, className)}
    >
      {children}
    </span>
  );
};

const Primary = (props: Omit<IProps, 'variant'>) => <Tag {...props} variant={'primary'} />;
const Secondary = (props: Omit<IProps, 'variant'>) => <Tag {...props} variant={'secondary'} />;
const Outlined = (props: Omit<IProps, 'variant'>) => <Tag {...props} variant={'outlined'} />;

Tag.Primary = Primary;
Tag.Secondary = Secondary;
Tag.Outlined = Outlined;

export default Tag;
