import classnames from 'classnames/bind';
import React, { FC, useRef, useState } from 'react';
import { VIEW_MODE, ViewModeType } from '../../interface/common/ViewMode';
import { HasOption } from '../../interface/common/hasOption';
import IViewHeader from '../../interface/header/IViewHeader';
import { checkIsDarkMode } from '../../util/editor/color.utils';
import Logo from './Logo';
import styles from './ViewHeader.module.scss';
import HeaderMyButton from './headerMyButton/HeaderMyButton';
import useViewHeaderAnimation from './useViewHeaderAnimation';
import Menu from './menu/Menu';
import { LANGUAGE, Language } from '../../constants/language';

const cx = classnames.bind(styles);

interface IViewHeaderProps {
  viewMode?: ViewModeType;
  viewHeader?: IViewHeader;
  isPreview?: boolean;
  isCapture?: boolean;
  isEdit?: boolean;
  title?: string;
}

const ViewHeader: FC<IViewHeaderProps & HasOption> = ({
  viewMode,
  viewHeader,
  isPreview = false,
  isCapture = false,
  isEdit = false,
  options,
  title,
}) => {
  const [isMobileDrawerOpen, setIsMobileDrawerOpen] = useState(false);
  const logoContainerRef = useRef<HTMLDivElement>(null);
  const rightMenuContainerRef = useRef<HTMLDivElement>(null);

  const { top, width, viewHeaderRef, visible } = useViewHeaderAnimation({
    viewMode,
    isEdit,
    isPreview,
    isMobileDrawerOpen,
  });

  if (!viewHeader) return null;

  const { companyLogo, lnbList, headerStyle, main } = viewHeader;
  const { backgroundColor, color, divisionStatusType, align } = headerStyle;
  const languageType = (options && (options['languageType'] as Language)) || LANGUAGE.KOR;
  const hasLanguageChangeSwitch = options && options['hasLanguageChangeSwitch'];
  const handleLanguageSwitch = options && options['handleLanguageSwitch'];

  const getQnaUrl = (): string => {
    const url = new URL(window.location.href);
    const searchParams = new URLSearchParams(url.search);
    searchParams.set('viewType', 'QnA');
    if (isPreview) return `${window.location.pathname}?${searchParams.toString()}`;

    return `${main.page.url}?${searchParams.toString()}`;
  };

  const getResumeUpdatePageUrl = (): string => {
    const url = new URL(window.location.href);
    const searchParams = new URLSearchParams(url.search);
    searchParams.set('viewType', 'ResumeUpdate');

    return `${main.page.url}?${searchParams.toString()}`;
  };

  const headerPosition = () => {
    if (viewMode === VIEW_MODE.PC) return 'absolute';
    if (isCapture) return 'absolute';
    return 'fixed';
  };

  return (
    <div
      ref={viewHeaderRef}
      className={cx('area', viewMode, align, {
        border: divisionStatusType === 'ON',
        darkMode: checkIsDarkMode(backgroundColor),
        hidden: viewMode !== VIEW_MODE.PC && !visible,
      })}
      style={{
        position: headerPosition(),
        top: isCapture ? 0 : `${top}px`,
        width,
        backgroundColor,
      }}
    >
      <div className={cx('logo')} ref={logoContainerRef}>
        <Logo
          className={cx('logo')}
          companyLogo={companyLogo}
          isPreview={isPreview}
          isEdit={isEdit}
          handleCloseMobileDrawer={() => setIsMobileDrawerOpen(false)}
          main={main}
          options={options}
          darkMode={checkIsDarkMode(backgroundColor)}
          title={title}
        />
      </div>
      <Menu
        lnbList={lnbList}
        isMobileDrawerOpen={isMobileDrawerOpen}
        setIsMobileDrawerOpen={setIsMobileDrawerOpen}
        viewMode={viewMode}
        viewHeader={viewHeader}
        isPreview={isPreview}
        isEdit={isEdit}
        options={options}
      />
      <div className={cx('menu-container')} ref={rightMenuContainerRef}>
        {viewMode === VIEW_MODE.PC && hasLanguageChangeSwitch && (
          <div className={cx('language-menu')}>
            {Object.keys(LANGUAGE).map((language) => {
              return (
                <div
                  key={language}
                  className={cx(
                    'language',
                    { active: language === languageType },
                    { DARK: checkIsDarkMode(backgroundColor) },
                    { isEdit }
                  )}
                  onClick={() => {
                    handleLanguageSwitch?.(language);
                  }}
                >
                  {language}
                </div>
              );
            })}
          </div>
        )}
        <div className={cx('my-btn')}>
          <HeaderMyButton
            viewMode={viewMode}
            headerStyle={viewHeader.headerStyle}
            isPreview={isPreview}
            qnaOpt={{
              url: getQnaUrl(),
              type: 'INTERNAL',
            }}
            resumeUpdateOpt={{
              url: getResumeUpdatePageUrl(),
              type: 'INTERNAL',
            }}
            options={{ ...options, useLanguageSetting: hasLanguageChangeSwitch }}
          />
        </div>
      </div>
    </div>
  );
};

export default ViewHeader;
