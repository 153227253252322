import React, { FC } from 'react';
import { VIEW_MODE, ViewModeType } from '../../../interface/common/ViewMode';
import MenuList from './MenuList';
import { ILNBType, ILNBTypes, IMyPageLNBType } from '../../../interface/header/IMenu';
import Drawer from '../mobile/Drawer';
import IViewHeader from '../../../interface/header/IViewHeader';
import { IHeaderJobflexStyle, IHeaderStyle } from '../../../interface/header/IHeaderStyle';
import { MY_BUTTON_TEXT } from '../headerMyButton/HeaderMyButton';
import { HasOption } from '../../../interface/common/hasOption';
import { LANGUAGE, Language } from '../../../constants/language';

interface Props {
  lnbList: ILNBType<ILNBTypes>[];
  isMobileDrawerOpen: boolean;
  setIsMobileDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
  viewMode?: ViewModeType;
  viewHeader?: IViewHeader;
  isPreview?: boolean;
  isEdit?: boolean;
}

const Menu: FC<Props & HasOption> = ({
  viewMode,
  viewHeader,
  isPreview,
  isEdit,
  isMobileDrawerOpen,
  setIsMobileDrawerOpen,
  options,
}) => {
  if (!viewHeader) return null;

  const { lnbList, headerStyle } = viewHeader;
  const { backgroundColor, color, divisionStatusType, align } = headerStyle;
  const languageType = (options && (options['languageType'] as Language)) || LANGUAGE.KOR;

  const createMyButtonLnb = () => {
    const { qnaStatusType, myPageStatusType, myPageUrl, resumeUpdatePageStatusType } =
      headerStyle as IHeaderStyle<IHeaderJobflexStyle>;

    const myButtonLnb: ILNBType<IMyPageLNBType> = {
      formType: 'MY_PAGE',
      title: MY_BUTTON_TEXT.TOTAL[languageType],
      subMenuList: [],
      hideType: 'VISIBLE',
    };

    // RESUME_UPDATE
    resumeUpdatePageStatusType === 'ON' &&
      myButtonLnb.subMenuList.push({
        title: MY_BUTTON_TEXT.RESUME_UPDATE[languageType],
        formType: 'RESUME_UPDATE',
        hideType: 'VISIBLE',
      });

    // MY_PAGE
    myPageStatusType === 'ON' &&
      myButtonLnb.subMenuList.push({
        title: MY_BUTTON_TEXT.MY_PAGE[languageType],
        formType: 'MY_PAGE',
        url: myPageUrl,
        hideType: 'VISIBLE',
      });

    // QNA
    qnaStatusType === 'ON' &&
      myButtonLnb.subMenuList.push({ title: MY_BUTTON_TEXT.QNA[languageType], formType: 'QNA', hideType: 'VISIBLE' });

    return !!myButtonLnb.subMenuList.length ? [myButtonLnb] : [];
  };

  switch (viewMode) {
    case VIEW_MODE.PC:
      return (
        <MenuList
          lnbList={lnbList as ILNBType<ILNBTypes>[]}
          isPreview={isPreview}
          isEdit={isEdit}
          style={{ color, backgroundColor, align }}
          options={options}
        />
      );
    default:
      return (
        <Drawer
          viewMode={viewMode}
          lnbList={[...lnbList, ...createMyButtonLnb()] as ILNBType<ILNBTypes>[]}
          isOpen={isMobileDrawerOpen}
          setIsOpen={setIsMobileDrawerOpen}
          isPreview={isPreview}
          isEdit={isEdit}
          style={{ color, align }}
          options={options}
          viewHeader={viewHeader}
        />
      );
  }
};

export default Menu;
