import { useRef, useEffect } from 'react';
import useOnClickOutside from '../../hook/useOnClickOutside';

interface IProps {
  isDialog?: boolean;
  close: () => void;
  pressEnter?: () => void;
}

const useModalEvent = ({ isDialog, pressEnter, close }: IProps) => {
  const contentRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(contentRef, () => isDialog && close());

  useEffect(() => {
    const handleKeyDown = async (e: KeyboardEvent) => {
      switch (e.key) {
        case 'Enter':
          if (!pressEnter) return;
          e.preventDefault();
          await pressEnter();
          return;
        case 'Escape':
          e.preventDefault();
          close();
          return;
        default:
          return;
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [pressEnter]);

  return { contentRef };
};

export default useModalEvent;
