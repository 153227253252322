import IViewFooter from '../interface/footer/IViewFooter';
import { IFooterDto } from '../interface/ITemplatePageRs';

const useViewFooterWithResponse = () => {
  const getViewFooterWithResponse: (footer: IFooterDto) => IViewFooter = (footer) => {
    const { text, style, footerMenuList, snsList } = footer;
    return {
      footerLinkList: footerMenuList,
      footerSNSList: snsList,
      colorType: style.colorType,
      footerText: text,
    };
  };

  return {
    getViewFooterWithResponse,
  };
};

export default useViewFooterWithResponse;
