// Created by kdw0601 on 2022-12-29
import { useEffect, useRef, useState } from 'react';
import Checkbox from '../checkbox/Checkbox';
import Icon from '../icon/Icon';
import Radio from '../radio/Radio';
import List, { MultipleVariant, SingleVariant } from './List';
import styles from './List.module.scss';
import classNames from 'classnames/bind';
import IListOption from './IListOption';

const cx = classNames.bind(styles);

interface IProps<T> extends IListOption<T> {
  onClick: (val: T) => void;
  variant?: SingleVariant | MultipleVariant;
  selected?: boolean;
}

const ListItem = <T,>({
  disabled,
  name,
  color,
  subText,
  value,
  hidden,
  onClick,
  variant,
  selected,
  subMenu,
  label,
  icon,
}: IProps<T>) => {
  const [isMounted, setIsMounted] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [subMenuDirection, setSubMenuDirection] = useState('right');
  useEffect(() => {
    if (!subMenu) return;

    const handler = () => {
      const rect = wrapperRef.current?.getBoundingClientRect();
      const openSubMenuLeft = rect ? window.innerWidth - rect.right - rect.width - 10 < 0 : false;
      setSubMenuDirection(openSubMenuLeft ? 'left' : 'right');
    };

    handler();
    window.addEventListener('resize', handler);

    return () => {
      window.removeEventListener('resize', handler);
    };
  }, [subMenu]);

  // 선택된 항목일 시 포커스
  useEffect(() => {
    if (!isMounted) {
      setIsMounted(true);
      return;
    }

    if (!variant?.startsWith('check') && selected) {
      buttonRef.current?.focus();
    }
  }, [isMounted]);

  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (subMenu) return;
    onClick(value);
  };

  if (hidden) return null;

  return (
    <div className={cx('item-wrapper')} ref={wrapperRef}>
      <button
        onMouseOver={(e) => {
          e.stopPropagation();
          e.currentTarget.focus();
        }}
        ref={buttonRef}
        className={cx('item', subMenu && 'has-sub-menu', selected && 'selected')}
        disabled={disabled}
        style={{ color }}
        onMouseDown={handleClick}
      >
        {variant?.startsWith('icon') && icon && <Icon className={cx('icon')} name={icon} size={18} />}
        {variant?.startsWith('radio') && (
          <Radio readOnly tabIndex={-1} disabled={disabled} className={cx('checkbox')} checked={selected} />
        )}
        {variant?.startsWith('check') && (
          <Checkbox tabIndex={-1} disabled={disabled} className={cx('checkbox')} checked={selected} readOnly />
        )}
        <div className={cx('name-wrap')}>
          {variant?.endsWith('sub') && subText && <div className={cx('sub')}>{subText}</div>}
          <div className={cx('name')}>{name}</div>
        </div>
        {subMenu ? (
          <div className={cx('more-icon-wrap')}>
            <Icon name={'arrow_right_line'} size={18} />
          </div>
        ) : (
          label && <div className={cx('label')}>{label}</div>
        )}
      </button>
      {subMenu && <List options={subMenu} className={cx('sub-menu', subMenuDirection)} onChange={onClick} />}
    </div>
  );
};

export default ListItem;
