export const Align = {
  LEFT: 'LEFT',
  CENTER: 'CENTER',
  RIGHT: 'RIGHT',
} as const;

export type AlignType = keyof typeof Align;

export const convertAlignToFlex = (align: AlignType) => {
  switch (align) {
    case 'CENTER':
      return 'center';
    case 'RIGHT':
      return 'flex-end';
    case 'LEFT':
      return 'flex-start';
  }
};
