import ISimpleListRsFindPageUrlRs from 'ats-recruiter/src/interface/design/ISimpleListRsFindPageUrlRs';
import { usePathname } from 'next/navigation';
const usePageMetadata = (pageData?: ISimpleListRsFindPageUrlRs) => {
  const pathname = usePathname();

  /** pathname에서 슬래시(/) 제외한 이름만 받아오는 메소드 */
  const getPathName = () => pathname?.split('/').pop();

  /** 메인페이지 Title을 찾아오는 메소드 */
  const findMainPageTitle = () => {
    const page = pageData?.pageList.find((page) => page.mainPageYn);
    return page?.pageTitle;
  };
  /** urlPath를 받아서 pageTitle을 찾아오는 메소드 */
  const findPageTitle = (urlPath: string) => {
    if (urlPath === '') return findMainPageTitle();
    const page = pageData?.pageList.find((page) => page.urlPath === urlPath);
    return page?.pageTitle;
  };

  return {
    companyName: pageData?.title,
    pageList: pageData?.pageList,
    getPathName,
    findPageTitle,
  };
};

export default usePageMetadata;
