import classnames from 'classnames/bind';
import style from './ModalHeader.module.scss';
import { FC } from 'react';

const cx = classnames.bind(style);

interface IProps {
  children: React.ReactNode;
}

const ModalHeader: FC<IProps> = ({ children }) => {
  return <div className={cx('container')}>{children}</div>;
};

export default ModalHeader;
