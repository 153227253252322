import { getBaseURL } from 'ui/api/requestClient/common';
import { IFile, Nullable } from 'ui/interface/common/common';

export const isEmptyFile = (file: IFile) => !file.fileUid && !file.fileUrl;
export const clearFile = (): IFile => ({ fileUid: undefined, fileUrl: undefined });
export const getFileUid = (file: IFile) => file.fileUid;

export const getImgUrlMetaData = (url: string) =>
  new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(img);
    img.onerror = (err) => reject(err);
    img.src = url;
  });

export const getImageURL = (fileInfo: Nullable<IFile>) => {
  if (!fileInfo) return null;
  const { fileUid, fileUrl } = fileInfo;
  if (fileUrl) return fileUrl;
  if (fileUid) return `${getBaseURL()}/file/v1/view/${fileUid}`;
  return null;
};

export async function getImageSize({ fileUid, fileUrl }: IFile) {
  const url = getImageURL({ fileUid, fileUrl });
  if (!url) throw Error("can't find url");
  const imgMetaData = (await getImgUrlMetaData(url)) as HTMLImageElement;

  return { width: imgMetaData.naturalWidth, height: imgMetaData.naturalHeight };
}

//BASE64 -> FILE CONVERT
export const dataURLtoFile = (dataUrl: string) => {
  let arr = dataUrl.split(','),
    mime = arr[0]!.match(/:(.*?);/)![1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], 'capture-image.jpg', { type: mime });
};
