import IToastMessageProps from './IToastMessageProps';
import { useState } from 'react';

export type ToastContextValueType = {
  toastList: (IToastMessageProps & { key: number })[];
  //FIXME 타입 임시처리
  setToastList: (toast: any) => void;
};

export const initialToastContextValue: ToastContextValueType = {
  toastList: [],
  setToastList: () => null,
};

const useToastContextValue = () => {
  const [toastList, setToast] = useState<(IToastMessageProps & { key: number })[]>([]);

  const setToastList = (toast: (IToastMessageProps & { key: number })[]) => {
    setToast(toast);
  };

  return {
    toastList,
    setToastList,
  };
};

export default useToastContextValue;
