// Created by ksy0105 on 2022-03-14

import { FC, createContext, PropsWithChildren } from 'react';

import useModalContextValue, { initialModalContextValue, ModalContextValueType } from './modal/useModalContextValue';

import useToastContextValue, { initialToastContextValue, ToastContextValueType } from './toast/useToastContextValue';
import useLoadingContextValue, {
  initLoadingContextValue,
  LoadingContextValueType,
} from './loading/useLoadingContextValue';
import useConfirmContextValue, {
  ConfirmContextValueType,
  initialConfirmContextValue,
} from './confirm/useConfirmContextValue';
import useAlertContextValue, { AlertContextValueType, initialAlertContextValue } from './alerts/useAlertContextValue';

type GlobalContextValueType = {
  modal: ModalContextValueType;
  confirm: ConfirmContextValueType;
  toast: ToastContextValueType;
  loading: LoadingContextValueType;
  alert: AlertContextValueType;
};

export const initialGlobalContextValue: GlobalContextValueType = {
  modal: initialModalContextValue,
  confirm: initialConfirmContextValue,
  toast: initialToastContextValue,
  loading: initLoadingContextValue,
  alert: initialAlertContextValue,
};

export const GlobalContext = createContext(initialGlobalContextValue);

export const GlobalContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const modal = useModalContextValue();
  const confirm = useConfirmContextValue();
  const toast = useToastContextValue();
  const loading = useLoadingContextValue();
  const alert = useAlertContextValue();

  return <GlobalContext.Provider value={{ modal, confirm, toast, loading, alert }}>{children}</GlobalContext.Provider>;
};

export default GlobalContext;
