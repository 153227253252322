// Created by ksy0105 on 2022-03-14

import { useState } from 'react';

export type ModalContextValueType = {
  modalCount: number;
  modalIdStack: number[];
  decreaseModalCount: () => void;
  increaseModalCount: () => void;
};

export const initialModalContextValue: ModalContextValueType = {
  modalCount: 0,
  increaseModalCount: () => null,
  decreaseModalCount: () => null,
  modalIdStack: [],
};

const useModalContextValue = () => {
  const [modalCount, setModalCount] = useState(0);
  const [modalIdStack] = useState<number[]>([]);

  const increaseModalCount = () => setModalCount((count) => count + 1);
  const decreaseModalCount = () => setModalCount((count) => count - 1);

  return { modalCount, increaseModalCount, decreaseModalCount, modalIdStack };
};

export default useModalContextValue;
