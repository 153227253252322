import { ReadonlyURLSearchParams, usePathname, useSearchParams } from 'next/navigation';
import { Nullable } from '../interface/common/common';

class UrlBuilder {
  private readonly _pathname: string;
  private _searchParams: Nullable<URLSearchParams>;

  constructor({ pathname, searchParams }: { pathname: string; searchParams?: URLSearchParams }) {
    this._pathname = pathname;
    this._searchParams = searchParams && new URLSearchParams(searchParams.toString());
  }

  withSearchParam<T extends Nullable<string | number>>(key: string, _value: T, encode = true) {
    if (!_value) {
      this.deleteSearchParam(key);
      return this;
    }
    if (!this._searchParams) this._searchParams = new URLSearchParams();
    const value = encode ? encodeURI(`${_value}`) : _value;
    this._searchParams.set(key, `${value}`);
    return this;
  }

  deleteSearchParam(key: string) {
    if (!this._searchParams) return this;
    this._searchParams.delete(key);
    return this;
  }

  getResult() {
    const search = this._searchParams?.toString() ?? '';
    return `${this._pathname}${search ? `?${search}` : ''}`;
  }
}

export const useUrl = () => {
  const pathname = usePathname();
  const searchParams = useSearchParams() as ReadonlyURLSearchParams;

  const getPathname = () => pathname;
  const getSearchParams = () => searchParams;
  const getSearchParam = <T extends string>(target: string, decode = true): Nullable<T> => {
    const value = searchParams.get(target);
    if (!value) return null;
    return (decode ? decodeURI(value) : value) as T;
  };

  const hasSearchParam = (target: string) => searchParams.has(target);

  const buildUrl = (pathname: string, searchParams?: URLSearchParams) => new UrlBuilder({ pathname, searchParams });

  return {
    getPathname,
    getSearchParams,
    getSearchParam,
    hasSearchParam,
    buildUrl,
  };
};
