import { useEffect, useState } from 'react';
import IViewHeader from 'ui/interface/header/IViewHeader';
import IViewPage from 'ui/interface/page/IViewPage';
import IViewFooter from 'ui/interface/footer/IViewFooter';
import { useQueryGetDesign } from '@queries/design';
import useViewHeaderWithResponse from 'ui/hook/useViewHeaderWithResponse';
import useViewPageWithResponse from 'ui/hook/useViewPageWithResponse';
import useViewFooterWithResponse from 'ui/hook/useViewFooterWithResponse';
import { notFound, useRouter, redirect } from 'next/navigation';
import axios from 'axios';
import usePageMetadata from 'ui/hook/usePageMetadata';
import { SOLUTION_PATH_PREFIX, SOLUTION_TYPE } from '@utils/../../../../packages/ui/util/solution';
import { SOLUTION } from 'ui/constants/solution/solution.constant';
import { useUrl } from 'ui/hook/useUrl';
import { LANGUAGE, Language } from 'ui/constants/language';
import { URL_PATH } from 'ui/hook/useUrlRouter';

const useViewData = () => {
  const [publicKey, setPublicKey] = useState<string>(''); // domain

  const [viewHeader, setViewHeader] = useState<IViewHeader>();
  const [viewPage, setViewPage] = useState<IViewPage>();
  const [viewFooter, setViewFooter] = useState<IViewFooter>();
  const [mainPageUrlPath, setMainPageUrlPath] = useState<string | undefined>('');

  const router = useRouter();
  const { getPathname, getSearchParams, getSearchParam, buildUrl } = useUrl();
  const pathname = getPathname();
  const searchParams = getSearchParams();
  const languageType = getSearchParam<Language>('languageType') ?? LANGUAGE.KOR;

  const { data, isSuccess, error } = useQueryGetDesign({ publicKey, languageType });
  const { getViewHeaderWithResponse } = useViewHeaderWithResponse();
  const { getViewPageWithResponse } = useViewPageWithResponse();
  const { getViewFooterWithResponse } = useViewFooterWithResponse();
  const pageMetadataHooks = usePageMetadata(data);

  useEffect(() => {
    if (!pathname) return;
    const host = window.location.host.toString().split('.')?.[0];
    setPublicKey(host);
  }, []);

  useEffect(() => {
    if (!data) return;
    if (!pathname) return;

    setMainPageUrlPath(
      SOLUTION_PATH_PREFIX[SOLUTION_TYPE](data.pageList.find((pageUrl) => pageUrl.mainPageYn)?.urlPath as string)
    );

    let url = '';
    let isSuccess = false;
    const findMainPage = () => data.pageList.find((pageUrl) => pageUrl.mainPageYn);
    const findSubPage = () =>
      data.pageList.find((pageUrl) => pathname === SOLUTION_PATH_PREFIX[SOLUTION_TYPE](pageUrl.urlPath));

    switch (true) {
      case window.document.location.href.includes('useQnA=true'):
        router.push(
          buildUrl(pathname, searchParams)
            .deleteSearchParam('useQnA')
            .withSearchParam('viewType', 'QnA')
            .withSearchParam('languageType', languageType !== LANGUAGE.KOR ? languageType : null)
            .getResult()
        );
        isSuccess = true;
        break;
      case pathname === '/':
        redirect(
          buildUrl(SOLUTION_PATH_PREFIX[SOLUTION_TYPE](`${findMainPage()?.urlPath ?? ''}`), searchParams)
            .withSearchParam('languageType', languageType !== LANGUAGE.KOR ? languageType : null)
            .getResult()
        );
        isSuccess = true;
        break;
      case /^\/article\//.test(pathname ?? ''):
      case /^\/career\/article/.test(pathname ?? ''):
        url = findMainPage()?.url ?? '';
        break;
      case /^\/jobs\//.test(pathname ?? ''):
      case /^\/career\/jobs/.test(pathname ?? ''):
        url = findSubPage()?.url ?? '';
        isSuccess = true;
        break;
      case pathname === '/career':
        if (SOLUTION_TYPE === SOLUTION.JOBFLEX) {
          if (!searchParams || ![...searchParams?.entries()].length) {
            router.push(
              buildUrl(SOLUTION_PATH_PREFIX[SOLUTION_TYPE](`${findMainPage()?.urlPath ?? ''}`))
                .withSearchParam('languageType', languageType !== LANGUAGE.KOR ? languageType : null)
                .getResult()
            );
            return;
          } else {
            url = document.location.href;
            break;
          }
        } else {
          url = findSubPage()?.url ?? '';
          break;
        }
      default:
        url = findSubPage()?.url ?? '';
        break;
    }

    if (!url && !isSuccess) {
      return notFound();
    }

    Promise.all([axios.get(data.headFileUrl), axios.get(url)]).then(([metaRes, bodyRes]) => {
      const metaData = metaRes.data;
      const bodyData = bodyRes.data;

      if (metaData?.header && metaData?.menu)
        setViewHeader(getViewHeaderWithResponse(metaData.header, metaData.menu, metaData.logo));
      if (metaData?.footer) setViewFooter(getViewFooterWithResponse(metaData.footer));
      if (bodyData?.storage) setViewPage(getViewPageWithResponse(bodyData.storage));
    });
  }, [isSuccess, languageType]);

  return {
    viewHeader,
    viewPage,
    viewFooter,
    mainPageUrlPath,
    pageMetadataHooks,
    isSuccess,
    error,
    title: data?.title,
  };
};

export default useViewData;
